import React from "react";
import { Row } from "antd";
import config from "../../util/ApiforContext";

const CardLogo = ({
  bankId,
  bankName,
  bankLogo,
  rowStyle,
  imageHeight,
  allList,
  cardLogoForUseWithBank,
  cardName,
}) => {
  const cardLogo = {
    10: "BankKart",
    12: "Paraf",
    15: "World",
    32: "Bonus",
    46: "Axess",
    48: "Advantage",
    59: "Bonus",
    62: "Bonus",
    64: "Maximum",
    67: "World",
    99: "Bonus",
    103: "Bonus",
    111: "CardFinans",
    124: "Bonus",
    125: "Bonus",
    134: "Bonus",
    146: "Axess",
    203: "World",
    206: "Bonus",
    209: "BankKart",
    211: "Paraf",
  };

  const cardWithBankLogo = {
    889: "Param",
    890: "NKolay",
    856: "PayTr",
    857: "Moka",
    836: "AkÖde",
    896: "Papara",
    1015: "RubikPara",
    845: "Vepara",
    849: "Payco",
    864: "İyzico",
  };

  if (cardLogo[bankId]) {
    const isMoreThanOne =
      (allList ?? []).filter((x) => cardLogo[x.bankId] == cardLogo[bankId])
        .length > 1;
    const baseUrl = config.apiUrl.replace("/api", "/Content/CardLogo");

    const logo = isMoreThanOne
      ? bankLogo
      : `${baseUrl}/${cardLogo[bankId]}.png`;

    return (
      <Row justify="center" style={rowStyle}>
        <img height={imageHeight ?? 50} src={logo} alt={bankName} />
      </Row>
    );
  }
  if (cardWithBankLogo[bankId]) {
    return cardLogoForUseWithBank !== null ? (
      <Row justify="center" style={{ flexDirection: 'column', alignItems: 'center', ...rowStyle }}>
      <img
        height={imageHeight ?? 50}
        src={cardLogoForUseWithBank}
        alt="cardName"
      />
      <img height={15} src={bankLogo} alt={bankName} />
    </Row>
    ) : (
      <Row justify="center" style={rowStyle}>
        <img height={imageHeight ?? 50} src={bankLogo} alt={bankName} />
        <p style={{ paddingTop: "inherit" }}>{cardName}</p>
      </Row>
    );
  }

  return (
    <Row justify="center" style={rowStyle}>
      <img height={imageHeight ?? 50} src={bankLogo} alt={bankName} />
    </Row>
  );
};

export default CardLogo;
